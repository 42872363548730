import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Drawer } from "antd";

import { pageStore } from "../../store/pageStore";
import { favoriteStore } from "../../store/favoriteStore";
import { getPictureUrl } from "../../helpers/picture/getPictureUrl";

import "./FavoritesDrawer.css";
import { PictureThumb } from "../PictureThumb/PictureThumb";

export const FavoritesDrawer = observer(() => {
  const hideDrawer = useCallback(() => {
    pageStore.setShowProfile(false);
  }, []);

  useEffect(() => {
    favoriteStore.fetchFavoriteObject();
  }, [favoriteStore.favoritesId]);

  const loadImage = async (picture) => {
    return await getPictureUrl(`1/${picture}_t`, "photos");
  };

  return (
    <Drawer
      title={
        <span className="FavoritesDrawer__Title">
          Your {favoriteStore.favoritesId.length} favorite
          {favoriteStore.favoritesId.length > 1 && "s"}
        </span>
      }
      placement="left"
      closable={true}
      onClose={() => hideDrawer(false)}
      visible={pageStore.showProfile}
      width="100%"
    >
      <div className="FavoritesDrawer__font">favorites:</div>
      {favoriteStore.favoriteObjectLoading ? (
        <div> Loading... </div>
      ) : (
        <div className="FavoritesDrawer__main">
          {favoriteStore.favoriteObject.map((picture) => {
            return (
              <div className="FavoritesDrawer__picture">
                <PictureThumb picture={picture} />
              </div>
            );
          })}
        </div>
      )}
    </Drawer>
  );
});
