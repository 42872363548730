import { action, makeObservable, observable } from "mobx";

export class PageStore {
  showMenu = false;
  showProfile = false;

  constructor() {
    makeObservable(this, {
      showMenu: observable,
      setShowMenu: action,
      showProfile: observable,
      setShowProfile: action,
    });
  }

  setShowMenu = async (showMenu) => {
    this.showMenu = showMenu;
  };

  setShowProfile = async (showProfile) => {
    this.showProfile = showProfile;
  };
}

export const pageStore = new PageStore();
