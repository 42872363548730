import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

import { getPictureUrl } from "../../../../helpers/picture/getPictureUrl";

import "./EditPage.css";

export const SimilarPicThumb = (props) => {
  const [s3SignedUrl, setS3SignedUrl] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { picture } = props;

  const loadImage = async () => {
    setIsLoading(true);
    // TODO: use createdBy as folder
    const url = await getPictureUrl(`1/${picture.key}`, "photos");
    try {
      await new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = url;
        loadImg.onload = () => resolve(url);
        loadImg.onerror = (err) => reject(err);
      });
    } catch (e) {
      setIsError(true);
      console.log(e);
    }
    setS3SignedUrl(url);
    setIsLoading(false);
  };

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <Tooltip title={`#${picture.id}`}>
      {isLoading ? (
        <div className="editPage__loadingOrError">
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      ) : isError ? (
        <div className="editPage__loadingOrError">
          <CloseOutlined style={{ fontSize: 24 }} />
        </div>
      ) : (
        <img
          className="editPage__similar"
          key={picture.id}
          id={picture.id}
          src={s3SignedUrl}
          alt={picture.id}
          width="100px"
        />
      )}
    </Tooltip>
  );
};
