import React from "react";
import { observer } from "mobx-react";
import { Avatar, Badge, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { userStore } from "../../../store/userStore";
import { authStore } from "../../../store/authStore";
import { pageStore } from "../../../store/pageStore";
import { Menu } from "./Menu/Menu";
import { Filter } from "./Filter/Filter";

import diaboliAvatar from "../../../img/diaboliAvatar.png";

import "./GalleryHeader.css";

export const GalleryHeader = observer(() => {
  const spinIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "goldenrod" }} spin />
  );

  return (
    <div className="galleryHeader__main" id="galleryHeader__main">
      <Filter />
      <div className="galleryHeader__title">kinkograph</div>
      <div className="galleryHeader__profile">
        {!authStore.isGuest && (
          <span
            onClick={() => pageStore.setShowMenu(!pageStore.showMenu)}
            className="link"
          >
            <Badge count={userStore.isLoading ? 0 : 0} offset={[0, 5]}>
              <Avatar
                src={
                  userStore.isLoading
                    ? null
                    : userStore.avatar
                      ? userStore.avatar
                      : diaboliAvatar
                }
                icon={userStore.isLoading && <Spin indicator={spinIcon} />}
                className="galleryHeader__avatar"
                size={50}
              />
            </Badge>
          </span>
        )}
        {pageStore.showMenu && <Menu />}
      </div>
    </div>
  );
});
