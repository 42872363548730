import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import "./Privacy.css";

export const Privacy = () => {
  return (
    <div className="privacy__container">
      <div className="privacy__containerBackground"></div>
      <div className="privacy__title">&nbsp;Privacy Settings</div>

      <div className="privacy__paragraph">
        <span>
          At Kinkograph, we value your privacy and are committed to protecting
          your personal information. This Privacy Settings Legal Disclaimer
          outlines how we handle your data, the options available to you for
          managing your privacy settings, and your rights in relation to your
          personal information.
        </span>
      </div>

      <div className="privacy__paragraph">
        <span>
          <span className="privacy__bold">
            Consent to Data Collection and Processing
          </span>
          &nbsp;- By using our website and services, you consent to the
          collection, use, and processing of your personal information as
          described in our [Privacy Policy]. You have the right to control the
          information you share with us and can adjust your privacy settings at
          any time through your account settings.
        </span>
      </div>

      <div className="privacy__paragraph">
        <span>
          <span className="privacy__bold">Managing Your Privacy Settings</span>
          &nbsp;- You can manage your privacy settings to control how your
          personal information is used and shared. These settings allow you to:
          <ul>
            <li>
              Control Visibility: Decide who can see your profile information,
              posts, and other content you share on our platform.
            </li>
            <li>
              Limit Data Sharing: Choose whether to share your information with
              third parties, including for marketing or analytics purposes.
            </li>
            <li>
              Ad Preferences: Opt out of personalized advertising based on your
              browsing behavior and preferences.
            </li>
            <li>
              Data Access and Portability: Request access to the personal
              information we hold about you, and request that we provide this
              information in a portable format.
            </li>
          </ul>
        </span>
      </div>

      <div className="privacy__paragraph">
        <span>
          <span className="privacy__bold">Opting Out of Data Sharing</span>
          &nbsp;- You have the option to opt out of sharing certain types of
          data with third parties. However, please note that opting out may
          impact your experience on our website, including the personalization
          of content and ads. Some data sharing may be necessary for the proper
          functioning of our services and cannot be disabled.
        </span>
      </div>

      <div className="privacy__paragraph">
        <span>
          <span className="privacy__bold">Data Retention</span>
          &nbsp;- We retain your personal information for as long as necessary
          to fulfill the purposes for which it was collected, including
          compliance with legal, regulatory, or contractual obligations. You can
          request the deletion of your data by adjusting your privacy settings
          or contacting our support team at support@kinkograph.com.
        </span>
      </div>

      <div className="privacy__paragraph">
        <span>
          <span className="privacy__bold">Your Rights</span>
          &nbsp;- You have the right to:
          <ul>
            <li>
              Access: Request a copy of the personal information we hold about
              you.
            </li>
            <li>
              Rectification: Request correction of any inaccurate or incomplete
              personal information.
            </li>
            <li>
              Erasure: Request the deletion of your personal information,
              subject to certain legal exceptions.
            </li>
            <li>
              Restriction: Request a restriction on the processing of your
              personal information under certain circumstances.
            </li>
            <li>
              Objection: Object to the processing of your personal information
              for direct marketing or other purposes.
            </li>
            <li>
              Withdraw Consent: Withdraw your consent for data processing at any
              time, where consent is the basis for processing.
            </li>
          </ul>
          <span>
            To exercise any of these rights, please update your privacy settings
            or contact our support team at support@kinkograph.com. We will
            respond to your request in accordance with applicable laws and
            within the timeframes specified by law.
          </span>
        </span>
      </div>

      <div className="privacy__paragraph">
        <span>
          <span className="privacy__bold">Amendments</span>
          &nbsp;- We may update this Privacy Settings Legal Disclaimer from time
          to time. Any changes will be posted on this page, and if the changes
          are significant, we will provide a more prominent notice. Your
          continued use of our services after the effective date of any
          amendments constitutes your acceptance of those changes.
        </span>
      </div>

      <div className="privacy__paragraph">
        <span>
          For more detailed information on how we collect, use, and protect your
          personal information, please review our [Privacy Policy]. If you have
          any questions or concerns about your privacy settings or this legal
          disclaimer, please contact our support team at support@kinkograph.com
        </span>
      </div>

      <Link className="privacy__returnLink" to="/">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};
