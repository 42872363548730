import React, { useEffect, useState } from "react";
import {
  LoadingOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { EditPage } from "../EditPage/EditPage";
import { getPictureUrl } from "../../../../helpers/picture/getPictureUrl";

import "./EditPictures.css";

export const EditPictures = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [s3SignedUrl, setS3SignedUrl] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditPage, setShowEditPage] = useState(false);

  const loadImage = async () => {
    setIsLoading(true);
    // TODO: use createdBy as folder
    const url = await getPictureUrl(`1/${props.picture.key}_t`, "photos");
    try {
      await new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = url;
        loadImg.onload = () => resolve(url);
        loadImg.onerror = (err) => reject(err);
      });
    } catch (e) {
      setIsError(true);
      console.log(e);
    }
    setS3SignedUrl(url);
    setIsLoading(false);
  };

  const mouseHoverHandler = (hover) => {
    if (props.totalMissingTag === null) {
      setShowEdit(hover);
      const element = document.getElementById(props.picture.id);
      if (hover) {
        element.style.filter = "brightness(50%) blur(2px)";
      } else {
        element.style.filter = "brightness(100%) blur(0px)";
      }
    }
  };

  const clickHandler = () => {
    setShowEditPage(true);
  };

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <>
      {showEditPage && (
        <EditPage
          reload={props.reload}
          setShowEditPage={setShowEditPage}
          picture={props.picture}
        />
      )}
      <div className="EditPictures__missingPicture">
        {props.totalMissingTag === null || props.totalMissingTag === 0 ? (
          showEdit && (
            <div
              className="EditPictures__missingPictureOverText"
              onMouseEnter={() => mouseHoverHandler(true)}
              onMouseLeave={() => mouseHoverHandler(false)}
              onClick={clickHandler}
            >
              <EditOutlined />
            </div>
          )
        ) : (
          <div className="EditPictures__missingPictureRest">
            {!isLoading && `+${props.totalMissingTag}`}
          </div>
        )}
        {isLoading ? (
          <div className="EditPictures__loader">
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        ) : isError ? (
          <div
            className="EditPictures__loader"
            id={props.picture.id}
            onMouseEnter={() => mouseHoverHandler(true)}
            onMouseLeave={() => mouseHoverHandler(false)}
            onClick={clickHandler}
          >
            <CloseOutlined style={{ fontSize: 24 }} />
          </div>
        ) : (
          <img
            className={props.totalMissingTag && "EditPictures__bluryGray"}
            id={props.picture.id}
            src={s3SignedUrl}
            alt={props.picture.id}
            width={props.size}
            height={props.size}
            onMouseEnter={() => mouseHoverHandler(true)}
            onMouseLeave={() => mouseHoverHandler(false)}
            onClick={clickHandler}
          />
        )}
      </div>
    </>
  );
};
