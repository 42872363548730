import React, { useEffect, useState, useCallback, Fragment } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import { patchPicture } from "../../../store/calls/patchPicture";
import { patchPictureAdult } from "../../../store/calls/patchPictureAdult";
import { getPictureUrl } from "../../../helpers/picture/getPictureUrl.js";

import "./PicAdmin.css";

export const PicThumbAdmin = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [s3SignedUrl, setS3SignedUrl] = useState(null);

  const loadImage = async () => {
    setIsLoading(true);
    // TODO: use createdBy as folder
    const url = await getPictureUrl(`1/${props.picture.key}_t`, "photos");
    try {
      await new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = url;
        loadImg.onload = () => resolve(url);
      });
    } catch (e) {
      console.error(e);
    }
    setS3SignedUrl(url);
    setIsLoading(false);
  };

  const checkHasHalo = useCallback(() => {
    let result = false;
    if (props.picture.tags) {
      result = JSON.parse(props.picture.tags).includes(props.tagSelected);
    }
    return result;
  }, [props.picture, props.tagSelected]);

  const checkIsAdult = useCallback(() => {
    const result = props.picture.adult_content;
    if (result === true) {
      return true;
    }
    return false;
  }, [props.picture, props.adultContentSelected]);

  const [hasHalo, setHasHalo] = useState(checkHasHalo());
  const [isAdult, setIsAdult] = useState(checkIsAdult());

  const pictureClickHandle = async (picture) => {
    if (props.tagSelected) {
      const tagsArray = JSON.parse(picture.tags);
      const indexTag = tagsArray.findIndex((tag) => {
        return tag === props.tagSelected;
      });
      if (!hasHalo) {
        // Add Tag to array
        tagsArray.push(props.tagSelected);
        setHasHalo(true);
      } else {
        // Delete Tag from array
        tagsArray.splice(indexTag, 1);
        setHasHalo(false);
      }
      // Path picture
      await patchPicture(tagsArray, props.picture.id);
      props.fetchAllPictures();
    } else if (props.adultContentSelected) {
      setIsAdult(!isAdult);
      await patchPictureAdult(!props.picture.adult_content, props.picture.id);
      props.fetchAllPictures();
    } else {
      props.setShowDrawer(true);
      props.setPictureSelected(picture);
    }
  };

  useEffect(() => {
    loadImage();
  }, []);

  useEffect(() => {
    setHasHalo(checkHasHalo());
  }, [props.tagSelected, props.adultContentSelected, checkHasHalo]);

  return (
    <>
      {isLoading ? (
        <div className="EditPictures__loader">
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      ) : (
        <img
          className={
            props.tagSelected !== null
              ? hasHalo
                ? "picAdmin__tagHalo"
                : "picAdmin__tagBlur"
              : isAdult && props.adultContentSelected
                ? "picAdmin__tagAdult"
                : "picAdmin__noFilter"
          }
          id={`pic_${props.picture.id}`}
          src={s3SignedUrl}
          alt={props.picture.id}
          key={props.picture.id}
          onClick={() => pictureClickHandle(props.picture)}
        />
      )}

      <span className="picAdmin__isAdult">
        {props.picture.adult_content && "+18"}
      </span>
      {props.picture.adult_content && " "}
      <span className="picAdmin__id">#{props.picture.id}</span>
    </>
  );
};
