import axios from "axios";

export const getGiveGuestAccess = async (code) => {
  try {
    const response = await axios({
      url: process.env.API_URL + `/auth/guest`,
      method: "GET",
    });

    return response;
  } catch (err) {
    if ("response" in err && err.response.status >= 400) {
      throw new Error(`${err.response.data.error}`);
    }
    throw err;
  }
};
