import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import "./Settings.css";

export const Settings = () => {
  return (
    <div className="settings__container">
      <div className="settings__containerBackground"></div>
      <div className="settings__title">&nbsp;Notification Settings</div>

      <div className="settings__paragraph">
        <span>
          By configuring your notification preferences on Kinkograph, you agree
          to receive communications in accordance with the options you have
          selected. These notifications may include, but are not limited to,
          emails, SMS messages, push notifications, and in -app alerts. The
          purpose of these notifications is to keep you informed about account
          activities, updates, promotional offers, and other relevant
          information.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">
            Consent to Receive Notifications
          </span>
          &nbsp;- By opting in to receive notifications, you consent to our use
          of your contact information to send you these communications. You
          acknowledge that some notifications may be necessary for the proper
          functioning of your account and cannot be disabled, such as those
          related to account security and critical updates.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">
            Changing Notification Preferences
          </span>
          &nbsp;- You have the right to modify your notification preferences at
          any time through your account settings. Please note that changes to
          your preferences may take up to 48 hours to be fully implemented.
          During this time, you may still receive notifications based on your
          previous settings.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Opting Out</span>
          &nbsp;- You may opt out of receiving certain types of notifications at
          any time. However, opting out of certain notifications may impact your
          ability to receive important updates and information related to your
          account and services. For SMS and email notifications, you may also
          opt out by following the unsubscribe instructions provided in the
          message.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Data Usage</span>
          &nbsp;- The contact information you provide for receiving
          notifications will be used in accordance with our [Privacy Policy /
          Terms of Service]. We will not share your contact information with
          third parties for marketing purposes without your explicit consent.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Legal Compliance</span>
          &nbsp;- We reserve the right to send you notifications as required by
          law or for legitimate business purposes. Even if you opt out of
          certain notifications, you may still receive legally required
          communications, such as those related to changes in our terms and
          conditions or data privacy policies.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Amendments</span>
          &nbsp;- We may update this Notification Settings Legal Disclaimer from
          time to time. Any changes will be posted on this page, and if the
          changes are significant, we will provide a more prominent notice. Your
          continued use of our services after the effective date of any
          amendments constitutes your acceptance of those changes.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          For further details, please refer to our[Privacy Policy / Terms of
          Service] or contact our support team at support@kinkograph.com
        </span>
      </div>

      <Link className="settings__returnLink" to="/">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};
