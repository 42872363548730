import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Select, Switch } from "antd";
import {
  SaveOutlined,
  DeleteOutlined,
  QuestionOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { capitalizeFirstLetter } from "../../../../helpers/capitalizeFirstLetter";
import { getTags } from "../../../../store/calls/getTags";
import { postTag } from "../../../../store/calls/postTag";
import { patchPicture } from "../../../../store/calls/patchPicture";
import { deletePicture } from "../../../../store/calls/deletePicture";
import { patchPictureAdult } from "../../../../store/calls/patchPictureAdult";
import { postSimilarFingerprint } from "../../../../store/calls/postSimilarFingerprint";
import { postDuplicateFingerprint } from "../../../../store/calls/postDuplicateFingerprint";
import { getPictureUrl } from "../../../../helpers/picture/getPictureUrl";
import { SimilarPicThumb } from "./SimilarPicThumb";

import "./EditPage.css";

const { Option } = Select;

export const EditPage = (props) => {
  const [s3SignedUrl, setS3SignedUrl] = useState(null);
  const [tags, setTags] = useState([]);
  const [isAdult, setIsAdult] = useState(props.picture.adult_content);
  const [allTags, setAllTags] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [similarFingerprints, setSimilarFingerPrints] = useState([]);
  const [duplicateFingerprints, setDuplicateFingerprints] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const loadImage = async () => {
    setIsLoading(true);
    // TODO: use createdBy as folder
    const url = await getPictureUrl(`1/${props.picture.key}`, "photos");
    try {
      await new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = url;
        loadImg.onload = () => resolve(url);
        loadImg.onerror = (err) => reject(err);
      });
    } catch (e) {
      setIsError(true);
      console.log(e);
    }
    setS3SignedUrl(url);
    setIsLoading(false);
  };

  const fetchAllTags = useCallback(async () => {
    try {
      const fetchedTags = await getTags();
      setAllTags(fetchedTags);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const closePage = useCallback(
    (needReload) => {
      props.setShowEditPage(false);
      if (needReload) {
        props.reload && props.reload();
      }
    },
    [props],
  );

  useEffect(() => {
    (async () => {
      const [similar, duplicate] = await Promise.all([
        postSimilarFingerprint(props.picture.id, 95, props.picture.fingerprint),
        postDuplicateFingerprint(props.picture.id, props.picture.fingerprint),
      ]);
      setSimilarFingerPrints(similar);
      setDuplicateFingerprints(duplicate);
    })();
  }, []);

  useEffect(() => {
    fetchAllTags();
    if (JSON.parse(props.picture.tags) !== null) {
      setTags(JSON.parse(props.picture.tags));
    }
  }, [fetchAllTags, props.picture.tags]);

  const handleTagChange = useCallback(async (value) => {
    const valueCleaned = value.map((oldTag) => {
      return capitalizeFirstLetter(oldTag);
    });
    setTags(valueCleaned);
  }, []);

  const handleSwitchIsAdult = async () => {
    await patchPictureAdult(!isAdult, props.picture.id);
    setIsAdult(!isAdult);
  };

  const submitHandler = useCallback(async () => {
    if (tags.length > 0) {
      // Add new Tags to db
      await tags.map(async (newTag) => {
        const index = allTags.findIndex(
          (tag) => tag === capitalizeFirstLetter(newTag),
        );
        if (index < 0) {
          // New Tag not found in db
          const result = await postTag(newTag);
          if (result.value === "success") {
            console.log(`${newTag} was added to the lists of tags.`);
          }
        }
        return undefined;
      });
      // Path picture
      await patchPicture(tags, props.picture.id);
      closePage(true);
    }
  }, [tags, allTags, closePage, props.picture.id]);

  const deleteHandler = useCallback(
    async (key) => {
      if (confirmDelete === true) {
        // Path picture
        await deletePicture(key);
        closePage(true);
      } else {
        setConfirmDelete(true);
        setTimeout(() => {
          setConfirmDelete(false);
        }, 2000);
      }
    },
    [confirmDelete, closePage],
  );

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <div className="editPage__container">
      <div
        className="editPage__closeButton"
        id="closeButton"
        onClick={() => {
          closePage(false);
        }}
      >
        <CloseOutlined />
      </div>
      {isLoading ? (
        <div className="editPage__picture">
          <div className="editPage__loadingOrErrorBig">
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        </div>
      ) : isError ? (
        <div className="editPage__picture">
          <div className="editPage__loadingOrErrorBig">
            <CloseOutlined style={{ fontSize: 24 }} />
          </div>
        </div>
      ) : (
        <div className="editPage__picture">
          <img
            src={s3SignedUrl}
            alt={props.picture.id}
            key={props.picture.id}
            style={{ maxWidth: "100%", maxHeight: window.innerHeight / 2.5 }}
          />
        </div>
      )}
      <br />
      <Select
        mode="tags"
        allowClear={false}
        style={{ width: "75vw" }}
        placeholder="Add some tags"
        onChange={handleTagChange}
        defaultValue={
          props.picture.tags !== null
            ? JSON.parse(props.picture.tags)
            : undefined
        }
      >
        {allTags.map((tag) => {
          return (
            <Option key={capitalizeFirstLetter(tag.tag)}>
              {capitalizeFirstLetter(tag.tag)}
            </Option>
          );
        })}
      </Select>
      <br />
      fingerpring: {props.picture.fingerprint}
      <br />
      <div className="editPage__adultContentFlag">
        <Switch defaultChecked={isAdult} onChange={handleSwitchIsAdult} />
        &nbsp; This is{!isAdult && " not"} adult content.
      </div>
      <br />
      {similarFingerprints.length > 0 && (
        <>
          <div>
            Similar picture
            {similarFingerprints.length > 0 && "s"}
          </div>
          {similarFingerprints.map((picture) => {
            return <SimilarPicThumb key={picture.id} picture={picture} />;
          })}
        </>
      )}
      {duplicateFingerprints.length > 0 && (
        <>
          <div>
            Duplicate picture
            {duplicateFingerprints.length > 0 && "s"}
          </div>
          {duplicateFingerprints.map((picture) => {
            return <SimilarPicThumb key={picture.id} picture={picture} />;
          })}
        </>
      )}
      <div className="editPage__buttonContainer">
        <div
          className={
            tags.length < 1 ? "editPage__buttonDisabled" : "editPage__button"
          }
          onClick={submitHandler}
        >
          <SaveOutlined /> &nbsp; Save
        </div>
        <div
          className={
            confirmDelete ? "editPage__buttonConfirmAction" : "editPage__button"
          }
          onClick={() => deleteHandler(props.picture.key)}
        >
          {confirmDelete ? (
            <Fragment>
              <DeleteOutlined /> ARE YOU SURE
              <QuestionOutlined />
            </Fragment>
          ) : (
            <Fragment>
              <DeleteOutlined />
              &nbsp; Delete
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
