import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { Badge, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { pictureStore } from "../../../../store/pictureStore";
import { capitalizeFirstLetter } from "../../../../helpers/capitalizeFirstLetter";

import "./Filter.css";

export const Filter = observer(() => {
  const { Option } = Select;
  const [showTransition, setShowTransition] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const throttling = useRef(false);

  const handleTagChange = (fitlerArray) => {
    pictureStore.setIsGalleryLoading(true);
    const fitlerArrayCleaned = fitlerArray.map((tag) => {
      return capitalizeFirstLetter(tag);
    });
    pictureStore.setFilter(fitlerArrayCleaned);
  };

  const handleSelectBlur = () => {
    if (pictureStore.filter.length === 0) {
      if (throttling.current === false) {
        throttling.current = true;
        setShowTransition(false);
        setTimeout(() => {
          throttling.current = false;
        }, 500);
      }
    }
  };

  const handleShowFilter = () => {
    if (throttling.current === false) {
      throttling.current = true;
      setShowTransition(!showTransition);
      setTimeout(() => {
        throttling.current = false;
      }, 500);
    }
  };

  useEffect(() => {
    const elementHeader = document.getElementById("galleryHeader__main");
    const elementFilter = document.getElementById("filter__opacity");
    if (showTransition) {
      elementHeader.style.height = "122px";
      setTimeout(() => {
        setShowFilter(showTransition);
        elementFilter.style.opacity = 1;
      }, 300);
    } else {
      elementFilter.style.opacity = 0;
      setTimeout(() => {
        setShowFilter(showTransition);
        elementHeader.style.height = "70px";
      }, 300);
    }
  }, [showTransition]);

  return (
    <div
      className="filter__container"
      onFocus={() => {
        pictureStore.setIsTagInputActive(true);
      }}
      onBlur={() => {
        pictureStore.setIsTagInputActive(false);
      }}
    >
      <div className="filter__filterIconContainer">
        <Badge
          count={pictureStore.filter.length}
          offset={[5, 10]}
          size="small"
          style={{ backgroundColor: "Goldenrod" }}
        >
          <SearchOutlined
            onClick={handleShowFilter}
            className="link filter__fitlerIcon"
          />
        </Badge>
      </div>

      <div
        id="filter__opacity"
        style={{ opacity: 0, transition: "opacity .3s" }}
      >
        {showFilter && (
          <Select
            allowClear={true}
            mode="multiple"
            defaultValue={pictureStore.filter}
            onChange={handleTagChange}
            onBlur={handleSelectBlur}
            className="filter__selectFilter"
            optionLabelProp="label"
            autoFocus={true}
            filterOption={(inputValue, option) => {
              // work around to make search case sensitive
              if (!option.value) {
                return false;
              }
              return option.value.includes(capitalizeFirstLetter(inputValue));
            }}
          >
            {pictureStore.tags.map((tag) => {
              return (
                <Option value={tag.tag} label={tag.tag} key={tag.tag}>
                  <div className="demo-option-label-item">
                    {tag.tag} <span className="light">({tag.occur})</span>
                  </div>
                </Option>
              );
            })}
          </Select>
        )}
      </div>
    </div>
  );
});
