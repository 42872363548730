// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/preview.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".welcome__main {  \n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;  \n    min-width: 100vw;  \n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    background-attachment: fixed;\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover; \n}\n\n.welcome__container {\n    /*\n    padding: 15px 15px 0px 15px;\n    border: 1px solid rgba(255,255,255,.15);\n    background-color: rgba(0,0,0,.75);\n    border-radius: 5px;\n    */\n    padding: 15px 15px 0px 15px;\n    \n}", "",{"version":3,"sources":["webpack://./src/pages/Welcome/Welcome.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,yDAA8C;IAC9C,sBAAsB;IACtB,4BAA4B;IAC5B,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI;;;;;KAKC;IACD,2BAA2B;;AAE/B","sourcesContent":[".welcome__main {  \n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;  \n    min-width: 100vw;  \n    background-image: url('../../img/preview.png');\n    background-size: cover;\n    background-attachment: fixed;\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover; \n}\n\n.welcome__container {\n    /*\n    padding: 15px 15px 0px 15px;\n    border: 1px solid rgba(255,255,255,.15);\n    background-color: rgba(0,0,0,.75);\n    border-radius: 5px;\n    */\n    padding: 15px 15px 0px 15px;\n    \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
